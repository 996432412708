import React from 'react'
import './Footer.css'

const Footer = () => {
  return (
    <div className={`footer iceland`}>
      <a
        href="https://www.dl-corsica.fr/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span>www.dl-corsica.fr</span>
      </a>
      {/*<span>SIRET : 88928363600021</span>*/}
      {/*<span>TVA : FR71889283636</span>*/}
    </div>
  )
}

export default Footer
