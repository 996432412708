import React from 'react'
import './CardGame.css'

const Message = ({ destinataire, matchingPW }) => {
  return (
    <>
      {destinataire.toLowerCase() !== 'vous' && (
        <>
          <span className="message-normal">{destinataire},</span>
          <br />
        </>
      )}
      <span className="message-normal">
        Digital Leisure{' '}
        <span
          className={`special-letter ${
            matchingPW[0] ? 'neon-green' : 'neon-red'
          }`}
        >
          C
        </span>
        orsica,
      </span>
      <span className="message-normal">
        Loisirs numériques au service de la Corse,
      </span>
      <br />
      <span className="message-normal">
        Nous avons créé en Corse, un pôle excellence, expe
        <span
          className={`special-letter ${
            matchingPW[2] ? 'neon-green' : 'neon-red'
          }`}
        >
          r
        </span>
        t dans les solutions technologiques ludiques : D.L.C.
      </span>
      <br />
      <span className="message-normal">
        Notre mission : val
        <span
          className={`special-letter ${
            matchingPW[1] ? 'neon-green' : 'neon-red'
          }`}
        >
          o
        </span>
        riser les richesses du territoire cors
        <span
          className={`special-letter ${
            matchingPW[4] ? 'neon-green' : 'neon-red'
          }`}
        >
          e
        </span>
        .
      </span>
      <br />
      <span className="message-normal">
        Nos moyen
        <span
          className={`special-letter ${
            matchingPW[3] ? 'neon-green' : 'neon-red'
          }`}
        >
          s
        </span>{' '}
        : la conception d’expériences immersives et interactives digitales.
      </span>
      <br />
      <span className="message-normal">Pace e salute !</span>
      <span className="message-normal">
        D.L.C. vous présente ses meilleurs vœux pour 2023.
      </span>
      <br />
      <span className="message-normal">
        Une année qui nous permettra sûrement de travailler ensemble sur des
        projets créatifs, passionnants et engagés.
      </span>
      <br />
      <span className="message-signature" style={{ marginTop: '20px' }}>
        L’équipe de D.L.C.
      </span>
    </>
  )
}

export default Message
