import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from 'react'
import './CardGame.css'
import Card from './Card'
import {
  fireSoundEffect,
  createDeck,
  easterEggMessage,
  getRandomHieroglyph,
} from './Game'
import { CardGameContext } from '../Lib/ContextManager'
import { sleep, randInt, fireAudioOnce } from '../Lib/FuncLib'
import { AppContext } from '../Lib/ContextManager'
import Message from './Message'
import Modal from './SubComponents/Modal'
import Logos from './SubComponents/Logos'

let delay = 2
const delay_ref = 2
const password = 'corse'
let phrases = Array(password.length).fill('')

const CardGame = ({ onEnd }) => {
  const { screen_lg, cheat, screen_s, screen_m, destinataire } =
    useContext(AppContext)
  const [initialTime, setInitialTime] = useState()
  const [time_now, setTime_now] = useState()
  const [currentDeck, setCurrentDeck] = useState([])
  const [currentCards, setCurrentCards] = useState([])
  const [eliminatedCards, setEliminatedCards] = useState([])
  const [animatingCards, setAnimatingCards] = useState([])
  const [gameEnded, setGameEnded] = useState(0)
  const [keywords, setKeywords] = useState('')
  const [easterEggPhase, setEasterEggPhase] = useState(0)
  const [tries, setTries] = useState(0)
  const [lastPair, setLastPair] = useState(null)
  const [previousLastPair, setPreviousLastPair] = useState(null)
  const [matchingPW, setMatchingPW] = useState(
    Array(password.length).fill(false)
  )
  const [canEnd, setCanEnd] = useState(false)
  const counterRef = useRef([true])

  const displayTime = useCallback((b, a) => {
    if (!b || !a) {
      return ''
    }
    const seconds = Math.floor((b - a) / 1000) - delay_ref
    if (seconds <= 0) {
      return ''
    } else {
      const minutes = Math.floor(seconds / 60)
      const hours = Math.floor(minutes / 60)
      const display_seconds = seconds % 60
      const display_minutes = minutes % 60
      return `${hours < 10 ? '0' + hours.toString() : hours.toString()}:${
        display_minutes < 10 ? `0${display_minutes}` : `${display_minutes}`
      }:${display_seconds < 10 ? `0${display_seconds}` : `${display_seconds}`}`
    }
  }, [])

  const initializeAfter = useCallback(async (id1, id2, ms) => {
    await sleep(ms)
    setAnimatingCards([id1, id2])
    setCurrentCards([])
    fireAudioOnce({ source: '/audiosrc/deal.wav', volume: 0.5 })
    await sleep(500)
    setAnimatingCards([])
  }, [])

  const eliminateAfter = useCallback(
    async (id, ms) => {
      await sleep(ms)
      let _eliminatedCards = [...eliminatedCards, id]
      setAnimatingCards([id])
      setEliminatedCards(_eliminatedCards)
      setCurrentCards([])
      if (_eliminatedCards.length >= currentDeck.length / 2) {
        setCanEnd(true)
        //endEvent()
      }
      await sleep(500)
      setAnimatingCards([])
    },
    [currentDeck.length, eliminatedCards]
  )

  const endEvent = useCallback(async () => {
    counterRef.current[0] = false
    setGameEnded(1)
    setLastPair(null)
    setPreviousLastPair(null)
    await sleep(100)
    onEnd()
    await sleep(100)
    window.scrollTo({ top: 0, behavior: 'smooth' })
    await sleep(1500)
    setGameEnded(2)
  }, [onEnd])

  const endGame = useCallback(async () => {
    let ids = currentDeck.map((e) => e.id)
    setEliminatedCards([...new Set(ids)])
    endEvent()
  }, [currentDeck, endEvent])

  const clickCallback = useCallback(
    (card) => {
      let uids = currentCards.map((e) => e.uid)
      if (uids.length < 2 && !uids.includes(card.uid)) {
        const newState = [...currentCards, { ...card }]
        fireAudioOnce({ source: '/audiosrc/flip.wav', volume: 0.3 })
        setCurrentCards(newState)
      } else if (uids.length === 1) {
        if (uids.includes(card.uid)) {
          setCurrentCards([])
          fireAudioOnce({ source: '/audiosrc/deal.wav', volume: 0.5 })
        } else {
          fireAudioOnce({ source: '/audiosrc/flip.wav', volume: 0.3 })
        }
      }
    },
    [currentCards]
  )

  const launchEasterEggPhase = useCallback(async () => {
    setEasterEggPhase(1)
    await sleep(1000)
    setEasterEggPhase(2)
  }, [])

  useEffect(() => {
    let lengths = [
      randInt(8, 16),
      randInt(8, 16),
      randInt(8, 16),
      randInt(6, 12),
      randInt(4, 8),
    ]
    for (let i = 0; i < phrases.length; i++) {
      let arr = []
      for (let j = 0; j < lengths[i]; j++) {
        arr.push(getRandomHieroglyph())
      }
      phrases[i] = arr.join('')
    }
    let _deck = createDeck({
      allow_duplicate_hieroglyph: false,
    })
    setCurrentDeck(_deck)
    setInitialTime(new Date().getTime())
    const intervalId = setInterval(() => {
      if (delay) {
        delay--
      } else if (counterRef.current[0]) {
        setTime_now(new Date().getTime())
      }
    }, 1000)
    return () => clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (currentCards.length === 2) {
      let id1 = currentCards[0].id
      let id2 = currentCards[1].id
      if (id1 === id2) {
        setLastPair(currentCards[0])
        setPreviousLastPair(currentCards[0])
        eliminateAfter(id1, 500)
        fireSoundEffect()
      } else {
        initializeAfter(id1, id2, 1000)
      }
      setTries((prev) => prev + 1)
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCards])

  useEffect(() => {
    const source = keywords.toLowerCase().trim()
    // switch neon light
    let matched_words = [...matchingPW]
    for (let i = 0; i < password.length; i++) {
      matched_words[i] = Boolean(source[i] === password[i])
    }
    setMatchingPW(matched_words)
    // determines if the password is right
    if (gameEnded && source === 'corse' && Boolean(!easterEggPhase)) {
      launchEasterEggPhase()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keywords])

  return (
    <CardGameContext.Provider
      value={{
        currentCards: currentCards,
        eliminatedCards: eliminatedCards,
        animatingCards: animatingCards,
        gameEnded: gameEnded,
        delay: delay,
      }}
    >
      {cheat && !gameEnded && <button onMouseDown={endGame}>CHEAT</button>}
      <div
        className={`cardgame-container`}
        style={{
          opacity: gameEnded === 1 ? 0 : 1,
        }}
      >
        {time_now ? (
          <>
            {easterEggPhase >= 2 ? (
              <></>
            ) : gameEnded > 1 ? (
              <>
                <span className="counter counter-neon-score">Mon score</span>
                <br />
                <span className="counter counter-neon-score">
                  {displayTime(time_now, initialTime)} /{' '}
                  {`${tries > 1 ? `${tries} essais` : `${tries} essai`}`}
                </span>
              </>
            ) : (
              <>
                <span className="counter counter-neon-game">
                  {displayTime(time_now, initialTime)}
                </span>
                <br />
                <span className="counter counter-neon-game">{`${
                  tries > 1 ? `${tries} essais` : `${tries} essai`
                }`}</span>
              </>
            )}
          </>
        ) : (
          <>
            <span className="counter">{String.fromCharCode(160)}</span>
            <br />
            <span className="counter">{String.fromCharCode(160)}</span>
          </>
        )}
        {gameEnded >= 2 && (
          <div
            className={`container-sheet container-relative`}
            style={{
              fontSize: screen_lg ? '2rem' : '1.5rem',
              opacity: easterEggPhase === 1 ? 0 : 1,
            }}
          >
            {easterEggPhase >= 2 ? (
              <>
                {easterEggMessage.map((e, i) => {
                  return (
                    <React.Fragment key={i}>
                      <span className="message-normal">{e}</span>
                    </React.Fragment>
                  )
                })}
                <Logos />
                {/* <Social /> */}
              </>
            ) : (
              <>
                <Message destinataire={destinataire} matchingPW={matchingPW} />
                <input
                  disabled={Boolean(easterEggPhase)}
                  className="login-input"
                  placeholder="CODE CACHÉ"
                  type="text"
                  value={keywords}
                  onChange={(e) => setKeywords(e.target.value)}
                  maxLength={password.length}
                />
              </>
            )}
          </div>
        )}
        {gameEnded < 2 && (
          <>
            <div
              className="wrapper-absolute"
              style={{
                opacity: Boolean(gameEnded) ? 0 : 1,
              }}
            >
              <div
                className="container-absolute"
                style={{
                  fontSize: `${
                    screen_lg ? '2' : screen_m ? '1.6' : screen_s ? '1.3' : '1'
                  }rem`,
                }}
              >
                {phrases.map((e, idx) => {
                  return (
                    <span key={idx} className="message-normal">
                      {e}
                    </span>
                  )
                })}
              </div>
              {currentDeck &&
                currentDeck.map((e) => (
                  <Card
                    key={e.uid}
                    id={e.id}
                    uid={e.uid}
                    imgsrc={e.imgsrc}
                    thumbnail={e.thumbnail}
                    clickCallback={() => clickCallback(e)}
                    hieroglyph={e.hieroglyph}
                    //containerSize={containerSize}
                  />
                ))}
            </div>
          </>
        )}
      </div>
      {(gameEnded < 2 && Boolean(lastPair)) || previousLastPair ? (
        <Modal
          lastPair={lastPair ? lastPair : previousLastPair}
          dismiss={
            canEnd
              ? () => endEvent()
              : lastPair
              ? () => {
                  setLastPair(null)
                }
              : undefined
          }
          gameEnded={gameEnded}
        />
      ) : (
        <></>
      )}
    </CardGameContext.Provider>
  )
}

export default CardGame
