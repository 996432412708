import { fireAudioOnce, randInt, removeAt } from '../Lib/FuncLib'
import { deepCopy } from '../Lib/FuncLib'

const hieroglyphs = [
  '𓍝',
  '𓅓',
  '𓁨',
  '𓃰',
  '𓆲',
  '𓆈',
  '𓃗',
  '𓃠',
  '𓁳',
  '𓁈',
  '𓂀',
  '𓅊',
  '𓋍',
  '𓎴',
  '𓋇',
  '𓃲',
  '𓀤',
  '𓁝',
  '𓁭',
  '𓃨',
]

export const getRandomHieroglyph = () => {
  let set = [...hieroglyphs]
  let _int = randInt(0, set.length - 1)
  return set[_int]
}

const cards = [
  {
    imgsrc: '/imgsrc/dlc/anti_corruption.jpeg',
    thumbnail: '/imgsrc/dlc/anti_corruption_thumbnail.jpg',
    title: 'LOI ANTI CORRUPTION',
    description: 'ESCAPE WEB FORMATIVE ',
    /* legend: '[Legende]', */
    id: 'a',
  },
  {
    imgsrc: '/imgsrc/dlc/codex.jpg',
    thumbnail: '/imgsrc/dlc/codex_thumbnail.jpg',
    title: 'L’affaire Pétrarque, CODEX et INFO(X)',
    description:
      'Escape web de vulgarisation de culture scientifique pour la nuit des chercheurs et la fête de la science.',
    /* legend: '[Legende]', */
    id: 'b',
  },
  {
    imgsrc: '/imgsrc/dlc/la_saga.png',
    thumbnail: '/imgsrc/dlc/la_saga_thumbnail.png',
    title: 'La Saga',
    description:
      'Escape Game Mobile de 5 à 300 personnes - Team Building - Incentives - Formation.',
    id: 'c',
    link: 'https://www.mindquest-games.fr/#ESCAPEGAMEMOBILE',
  },
  {
    imgsrc: '/imgsrc/dlc/les_infiltres.png',
    thumbnail: '/imgsrc/dlc/les_infiltres_thumbnail.png',
    title: 'ESCAPE WEB : Les Infiltrés',
    description:
      '( Escape WEB 4.0 : Escape Game, mini-jeux vidéo, enquête, une aventure multimédia interactive. 8 fins différentes ! )',
    id: 'd',
    link: 'https://www.mindquest-games.fr/boutiques/infiltres/',
  },
  {
    imgsrc: '/imgsrc/dlc/festival_loire.jpeg',
    thumbnail: '/imgsrc/dlc/festival_loire_thumbnail.jpg',
    title: 'Co-Organisation du Festival de Loire',
    description:
      'Le plus grand rassemblement de la marine fluviale (+ de 300 000 visiteurs) ',
    id: 'e',
  },
  {
    imgsrc: '/imgsrc/dlc/laicite.jpeg',
    thumbnail: '/imgsrc/dlc/laicite_thumbnail2.jpg',
    title: ' Prix de la laïcité 2022',
    description:
      'Remise du Prix de la laïcité 2022 par les ministres Marlène Schiappa, Sonia Backès, et Sarah El Haïry pour l’ESCAPE WEB LAÏCITÉ',
    id: 'f',
  },
  {
    imgsrc: '/imgsrc/dlc/memento.png',
    thumbnail: '/imgsrc/dlc/memento_thumbnail.png',
    title: 'MEMENTO',
    description:
      'Application mobile expérience immersive de valorisation du patrimoine et de l’Histoire. ',
    id: 'g',
    link: 'https://www.mairie-eguilles.fr/fr/tourisme/memento-eguilles',
  },
  {
    imgsrc: '/imgsrc/dlc/memory.jpeg',
    thumbnail: '/imgsrc/dlc/memory_thumbnail.jpg',
    title: 'MEMORY',
    description:
      'Jeu MEMORY  sur l’Histoire Corse  (public : Enfants et adolescents)  ',
    id: 'h',
  },
]

export const createDeck = ({ allow_duplicate_hieroglyph }) => {
  let deck = [...cards]
  let res = []
  let _hieroglyphs = [...hieroglyphs]
  while (deck.length) {
    let idx = randInt(0, deck.length - 1)
    let card1 = deck[idx]
    let card2 = deepCopy(deck[idx])
    card1.uid = Math.random() + Math.random()
    card2.uid = Math.random() + Math.random()
    let pair = [card1, card2]
    for (let i = 0; i < pair.length; i++) {
      if (allow_duplicate_hieroglyph) {
        pair[i].hieroglyph = getRandomHieroglyph()
      } else {
        let h_idx = randInt(0, _hieroglyphs.length - 1)
        pair[i].hieroglyph = _hieroglyphs[h_idx]
        _hieroglyphs = removeAt(_hieroglyphs, h_idx)
      }
    }
    res.push(card1)
    res.push(card2)
    deck = removeAt(deck, idx)
  }
  const shuffled = res.sort((x, y) => x.uid - y.uid)
  return shuffled
}

export const easterEggMessage = [
  'Félicitations !',
  'Je savais que vous parviendriez à décoder ce message.',
  'Vous voilà prêt à rejoindre le rang des personnes qui nous ont fait confiance… ',
  '',
]

const soundEffects = [
  // "flip", 240776__f4ngy__card-flip
  // "reverse"240777__f4ngy__reverse_dealing-card
  'sound-7.mp3',
  'sound-8.mp3',
  'sound-9.mp3',
  'sound-10.mp3',
]

export const fireSoundEffect = () => {
  const source = `/audiosrc/${
    soundEffects[randInt(0, soundEffects.length - 1)]
  }`
  fireAudioOnce({ source: source, volume: 1 })
}

export const logos = [
  {
    id: 'custom',
    image: {
      src: '/imgsrc/logos/example.png',
      alt: 'CUSTOM LOGO',
    },
    link: "mailto: cyril.villalonga@dl-corsica.fr?subject=Et%20si%20l'on%20discutait%20ensemble%20de%20vos%20solutions%20numériques…",
    style: {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      overflow: 'hidden',
      borderRadius: '0.5rem',
      padding: '0px',
      border: '3px solid #f5f543',
    },
  },
  {
    id: 'intel',
    image: {
      src: '/imgsrc/logos/intel_2022.png',
      alt: 'Intel',
    },
    link: null,
  },
  {
    id: 'm6',
    image: {
      src: '/imgsrc/logos/m6.png',
      alt: 'M6',
    },
    link: null,
  },
  {
    id: 'ffb',
    image: {
      src: '/imgsrc/logos/ffb.png',
      alt: 'FFB',
    },
    link: null,
  },
  {
    id: 'paris',
    image: {
      src: '/imgsrc/logos/paris_olympic_2024.png',
      alt: 'Paris',
    },
    link: null,
  },
  {
    id: 'solideo',
    image: {
      src: '/imgsrc/logos/solideo.png',
      alt: 'Solideo',
    },
    link: null,
  },
  {
    id: 'novartis',
    image: {
      src: '/imgsrc/logos/novartis.png',
      alt: 'Novartis',
    },
    link: null,
  },
  {
    id: 'unibio',
    image: {
      src: '/imgsrc/logos/unibio.png',
      alt: 'Unibio',
    },
    link: null,
  },
  {
    id: 'ca_bourgogne',
    image: {
      src: '/imgsrc/logos/ca_bourgogne.png',
      alt: 'Crédit Agricole Bourgogne',
    },
    link: null,
  },
  {
    id: 'radisson',
    image: {
      src: '/imgsrc/logos/radisson.png',
      alt: 'Hôtel Radisson',
    },
    link: null,
  },
  {
    id: 'provence_tourisme',
    image: {
      src: '/imgsrc/logos/provence_toursime.png',
      alt: 'Provence Toursime',
    },
    link: null,
  },
  {
    id: 'ministre_interieur',
    image: {
      src: '/imgsrc/logos/ministre_interieur.png',
      alt: "Ministre de l'Intérieur",
    },
    link: null,
  },
  {
    id: 'mairie_orleans',
    image: {
      src: '/imgsrc/logos/orleans.png',
      alt: "Mairie d'Orléans",
    },
    link: null,
  },
  {
    id: 'mairie_eguilles',
    image: {
      src: '/imgsrc/logos/eguilles.png',
      alt: "Mairie d'Éguilles",
    },
    link: null,
  },
  {
    id: 'euro_compliance',
    image: {
      src: '/imgsrc/logos/euro_compliance.png',
      alt: 'EuroCompliance',
    },
    link: null,
  },
  {
    id: 'univ_nimes',
    image: {
      src: '/imgsrc/logos/univ_unimes.png',
      alt: 'Université de Nîmes',
    },
    link: null,
  },
  {
    id: 'le_cnam',
    image: {
      src: '/imgsrc/logos/le_cnam.png',
      alt: 'Le Cnam',
    },
    link: null,
  },
  {
    id: 'madirel',
    image: {
      src: '/imgsrc/logos/madirel.png',
      alt: 'Madirel',
    },
    link: null,
  },
  {
    id: 'cielam',
    image: {
      src: '/imgsrc/logos/cielam.png',
      alt: 'Cielam',
    },
    link: null,
  },
  {
    id: 'cabinet expansi',
    image: {
      src: '/imgsrc/logos/expansi.png',
      alt: 'Cabinet Expansi',
    },
    link: null,
  },
  {
    id: 'quartus',
    image: {
      src: '/imgsrc/logos/quartus.png',
      alt: 'Quartus',
    },
    link: null,
  },
  {
    id: 'metamorph_studio',
    image: {
      src: '/imgsrc/logos/metamorph_studio.png',
      alt: 'Metamorph Studio',
    },
    link: null,
  },
  {
    id: 'journal_du_geek',
    image: {
      src: '/imgsrc/logos/journal_du_geek.png',
      alt: 'Journal du Geek',
    },
    link: null,
    style: {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      overflow: 'hidden',
      borderRadius: '0.5rem',
      padding: '0px',
    },
  },
  {
    id: 'geekopolis',
    image: {
      src: '/imgsrc/logos/geekopolis.jpg',
      alt: 'Geekopolis',
    },
    link: null,
    style: {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      overflow: 'hidden',
      borderRadius: '0.5rem',
      padding: '0px',
    },
  },
]
