import React from 'react'

const Logo = ({ isVerticalScreen }) => {
  return (
    <div
      className={`logo ${isVerticalScreen ? 'logo_mobile' : 'logo_desktop'}`}
      onClick={() => {
        window.open('https://www.dl-corsica.fr/', '_blank')?.focus()
      }}
    >
      <img src="/logo_dlc.png" alt="DLC" />
    </div>
  )
}

export default Logo
